<template>
  <main class="sx-coming-soon-main">
    <div class="sx-coming-soon-wrapper">
      <img
        :src="require('@/assets/img/icons/shapes/shape-1.svg')"
        alt=""
        class="shape shape-1"
      />
      <img
        :src="require('@/assets/img/icons/shapes/shape-2.svg')"
        alt=""
        class="shape shape-2"
      />
      <img
        :src="require('@/assets/img/icons/shapes/shape-3.svg')"
        alt=""
        class="shape shape-3"
      />
      <img
        :src="require('@/assets/img/icons/shapes/shape-4.svg')"
        alt=""
        class="shape shape-4"
      />
      <img
        :src="require('@/assets/img/icons/shapes/shape-5.svg')"
        alt=""
        class="shape shape-5"
      />
      <img
        :src="require('@/assets/img/icons/shapes/shape-6.svg')"
        alt=""
        class="shape shape-6"
      />
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-5 col-lg-6 col-md-6">
            <div class="img-wrapper">
              <img
                :src="require('@/assets/img/illustrations/coming-soon.svg')"
                alt=""
              />
            </div>
          </div>
          <div class="col-xl-7 col-lg-6 col-md-6 odd-col">
            <div class="content-wrapper">
              <img
                :src="$config.media.logos.light"
                class="col-3"
                alt="SetXrm Cloud Solutions"
              />
              <h1 class="">
                {{
                  $t(
                    "Components.ComingSoon.Title",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h1>
              <p
                class="alert alert-warning fs-4"
                v-html="
                  $t(
                    'Components.ComingSoon.Description',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
              ></p>
              <a
                :href="$config.webSites.support"
                class="btn btn-success fs-4"
                target="_blank"
              >
                <i class="bi-people bi fs-4"></i>
                {{
                  $t(
                    "BaseModelFields.SupportTeam",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</a
              >
              <div class="btn-group dropstart float-end">
                <button
                  type="button"
                  class="btn btn-secondary dropdown-toggle fs-4"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    class="rounded-1 me-1"
                    width="32"
                    height="32"
                    :src="`${require('@/assets/img/flags/' +
                      currentLangugeLocale.flag)}`"
                    :alt="currentLangugeLocale.name"
                  />
                  {{ currentLangugeLocale.name }}
                </button>
                <ul class="dropdown-menu">
                  <li
                    v-for="item in locales.filter((item) => {
                      return item.code !== activeLanguage;
                    })"
                    class="cursor-pointer"
                    :key="item.code"
                  >
                    <a
                      @click="setLang(item.code)"
                      class="dropdown-item"
                      :class="{ active: activeLanguage == item.code }"
                    >
                      <span class="me-2">
                        <img
                          width="32"
                          height="32"
                          class="rounded-1"
                          :src="`${require('@/assets/img/flags/' +
                            item.code +
                            '.png')}`"
                        />
                      </span>
                      <span class="fs-5">
                        {{
                          $t(
                            "Language." + item.code,
                            {},
                            { locale: activeLanguage }
                          )
                        }}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {
  getSupportedLocales,
  getCountries,
} from "@/core/config/supported-locales";
import "@/assets/css/coming-soon.css";
export default {
  name: "ComingSoon",
  data() {
    return {
      locales: getSupportedLocales(),
      countries: getCountries(),
      currentLangugeLocale: {},
      activeLanguage:
        localStorage.getItem("activeLang") || this.$store.state.activeLang,
    };
  },
  methods: {
    setLang(code) {
      this.activeLanguage = code;
      this.currentLangugeLocale = this.countries.find((f) => f.code == code);
      this.$root.setCurrentUserLanguage(code, true);
    },
  },
  created() {
    this.currentLangugeLocale = this.countries.find(
      (f) => f.code == this.activeLanguage
    );
    // TODO: kanalın dinlendiği yerde set edilecek.
    // localStorage.setItem("isMaintenanceMode", true);
  },
  mounted() {
    this.$root.changeDocumentTitle(
      this.$t("Components.ComingSoon.Title"),
      {},
      { locale: this.$store.state.activeLang }
    );
  },
};
</script>
